let loginAddress = localStorage.getItem("loginAddress");

async function login() {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    if (accounts.length > 0) {
        localStorage.setItem("loginAddress", accounts[0]);
        loginAddress = accounts[0];
    } else {
        localStorage.removeItem("loginAddress");
        loginAddress = null;
    }

    checkLogin();
}

function toggleAddress() {
    if(loginAddress) {
        $(".loginBtns .meta_dropdown").removeClass("hide");
        $("#login_address").text(loginAddress);
        $(".loginBtns .btns").addClass("hide");
    } else {
        $(".loginBtns .meta_dropdown").addClass("hide");
        $(".loginBtns .btns").removeClass("hide");
    }
}

function checkLogin() {
    toggleAddress();
}

let options = {
    method: 'POST',
    headers: {
        "Authorization": NODE_ENV["NFTPORT_API"],
    },
};

async function fetch_ipfs_file(form) {
    options["body"] = form
    let res = await fetch("https://api.nftport.xyz/v0/files", options)
    let data = await res.json();
    if (data["response"] == "OK") {
        $("#ipfs_file_uri").val(data["ipfs_url"])
        $("#ipfs_file_uri").parent().append("<span class='badge alert-success'>Done</span>")
    }
}

async function fetch_ipfs_metadata() {
    let body = JSON.stringify(
        {
        "name": $("#name").val(),
        "description": $("#description").val(),
        "file_url": $("#ipfs_file_uri").val(),
        "attributes": [
            {
            "trait_type": "roles",
            "value": $("#roles").val()
            },
            {
            "trait_type": "rarity",
            "value": Math.floor(Math.random() * 101)
            },
            {
            "trait_type": "skills",
            "value": $("#skills").val()
            }
        ]
        }
    )

    options["body"] = body
    let res = await fetch("https://api.nftport.xyz/v0/metadata", options)
    let data = await res.json();
    console.log(data);
    if (data["response"] == "OK") {
        $("#ipfs_metadata_uri").val(data["metadata_uri"])
        mint_nft()
    }
}

async function mint_nft() {
    let body = JSON.stringify(
        {
        "chain": "polygon",
        "contract_address": NODE_ENV["TARGET_CONTRACT_ADDRESS"],
        "metadata_uri": $("#ipfs_metadata_uri").val(),
        "mint_to_address": $("#address").val()
        }
    )

    options["body"] = body
    let res = await fetch("https://api.nftport.xyz/v0/mints/customizable", options)
    let data = await res.json();
    console.log(data);
    $("#spinner").addClass("hide");
    if (data["response"] == "OK") {
        alert("创建成功，大概五分钟之后即可在opensea上查看")
        window.location.reload();
    } else {
        alert(data["error"]);
    }
}

$(document).on('turbolinks:load', function() {
    'use strict';
    $(async function() {
        $('.datepicker').datepicker({
            language: 'zh-CN',
            format: "yyyy-mm-dd",
            autoclose: true,
            forceParse: true,
            todayBtn: true,
            startDate: moment().subtract(1, 'month').toDate(),
            endDate: moment().subtract(1, 'day').toDate(),
            initialDate: moment().subtract(1, 'day').toDate()
        });
        $('.datepicker-comparison').datepicker({
            language: 'zh-CN',
            format: "yyyy-mm-dd",
            autoclose: true,
            forceParse: true,
            todayBtn: true,
            endDate: moment().subtract(1, 'day').toDate(),
            initialDate: moment().subtract(1, 'day').toDate()
        });
        $('.datepicker-usergroup').datepicker({
            language: 'zh-CN',
            format: "yyyy-mm-dd",
            autoclose: true,
            forceParse: true,
            todayBtn: true,
            startDate: moment().subtract(3, 'years').toDate(),
            endDate: moment().subtract(1, 'day').toDate(),
            initialDate: moment().subtract(1, 'day').toDate()
        })
        $('[data-bs-toggle="tooltip"]').tooltip({html: true});
        $('.select2-dropdown').select2();

        $("#trading_histories_filter_page").on("click", ".histories", function() {
            var coin = $(this).text();
            var user = $(this).data("user");
            $(".collapse-" + user + "-"+ coin).collapse('toggle');
        })

        if (window.ethereum) {
            checkLogin();

            ethereum.on('accountsChanged', function (accounts) {
                console.log('accountsChanges',accounts);
                if (accounts.length > 0) {
                    localStorage.setItem("loginAddress", accounts[0]);
                    loginAddress = accounts[0];
                } else {
                    localStorage.removeItem("loginAddress");
                    loginAddress = null;
                }
                toggleAddress();
            });

        // detect Network account change
            ethereum.on('chainChanged', function(networkId){
                console.log('networkChanged',networkId);
            });
        }

        $("#btn-login").on("click", function(){
            login();
        });

        $(".add-nft-page").on("change", "#file", function(e) {
            const form = new FormData();
            form.append("file", e.target.files[0]);

            fetch_ipfs_file(form);
        })

        $(".add-nft-page").on("click", "#submitBtn", function(e) {
            e.preventDefault();
            $("#spinner").removeClass("hide");
            fetch_ipfs_metadata();
        })

        $(".trading-show-page").on("click", ".loadMoreBtn", function(e) {
            const page = $("#current_page").val();
            var url = new URL(window.location.href);
            var search_params = url.searchParams;
            search_params.set("page", (parseInt(page) + 1));
            var new_url = window.location.pathname + "?" + search_params.toString();
            $(this).attr("href", new_url);
        })

        $("#weekly_analysis_page").on("click", ".traderFilterBtn", function() {
            console.log("hello")
            $("#weekly_analysis_filter_form").submit();
        })
    });
})
