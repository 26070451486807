$(document).on('turbolinks:load', function() {
  'use strict';
  $(function() {
    $("#setting-col button").click(function(e){
      var checkbox = $(e.target).find("input");

      if($(checkbox).is(":checked")) {
        $(checkbox).prop("checked", false);
         $(e.target).removeClass("setting-col-btn");
      }else
      {
        $(checkbox).prop("checked", true);
        $(e.target).addClass("setting-col-btn");
      }
    })

  });
})